import BasePlugin from '../BasePlugin'

export default class SetInvestmentRequestBaseScenarioAsyncCommand extends BasePlugin {
  async execute () {
    let scenarioId = this.context.getModel().id
    this.context.getCard().setLoading(true)

    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/SetInvestmentRequestBaseScenarioAsyncCommand`,
      {
        'scenarioId': scenarioId,
        'async': 'true'
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => {
      this.context.getCard().setLoading(false)
      this.context.$msgbox({
        type: 'info',
        message: 'Задача поставлена в очередь. По завершении вы получите уведомление'
      })
      this.context.getCard().$emit('cancelChanges')
    })
  }
}
